<template>
  <div class="h-100 pending-teacher d-flex flex-column">
    <img :src="icon" alt="pending-icon" />
    <p class="text">
      {{ $t("pending_text") }}
    </p>
    <b-button variant="primary" @click="handelLogout">
      {{ $t("logout") }}
    </b-button>
  </div>
</template>
<script>
import { BButton } from "bootstrap-vue";
import useJwt from "@/auth/jwt/useJwt";

export default {
  name: "PendingTeacher",
  components: { BButton },
  data() {
    return {
      icon: require("@/assets/images/icons/pending.svg"),
    };
  },
  created() {
    const userData = JSON.parse(localStorage.getItem("userData"));
    if (
      userData?.is_approved ||
      (userData?.teacher && userData.teacher?.is_approved)
    ) {
      this.$router.push({ path: "/" });
    }
  },
  methods: {
    handelLogout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);

      // Remove userData from localStorage
      localStorage.removeItem("userData");

      // Redirect to login page
      this.$router.push({ name: "Login" });
    },
  },
};
</script>
<style lang="scss">
@import "@/assets/scss/style";
.pending-teacher {
  height: 100vh !important;
  justify-content: center !important;
  align-items: center;
  text-align: center;
  .text {
    font-size: 20px;
    font-weight: 500;
    color: $primary-color;
  }
}
</style>
